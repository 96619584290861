import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Log } from '../interfaces/log';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { auth0Context } from './service';

const Config = require('Config');

export default class LogService {
  axios: AxiosInstance;

  constructor() {
    let token = LocalAuth.getTokenValue();
    this.axios = Axios.create({
      baseURL: Config.loadManagementServiceUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async log(log: Log): Promise<any> {
    let headers: AxiosRequestConfig<Log>;
    if (auth0Context.isAuthenticated) {
      const token = await auth0Context.getAccessTokenSilently();
      headers.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const response: AxiosResponse = await this.axios.post(`client/log`, log, headers);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
