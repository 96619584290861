import { isNullOrWhitespace } from '../utils/string-utils';
import { Endorsement } from './endorsement';

export class Driver {
  id: number;
  companyId: number;
  firstName: string;
  lastName?: string;
  fullName?: string;
  phoneNumber: string;
  email?: string;
  nickname?: string;
  dateOfBirth?: string;
  isActive: boolean;
  missingFirstName?: false;
  missingPhoneNumber?: false;
  profileImageKey?: string;
  // CDL
  cdlNumber?: string;
  cdlState?: string;
  cdlExpiration?: string;
  cdlClass?: string;
  cdlEndorsements?: Endorsement[];
  // ADDRESS
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  //EMERGENCY
  emergencyContact: string;
  emergencyPhoneNumber: string;
  businessPartnerName?: string;
  transactionKey: string;
  isOwnerOperator?: boolean;
  cachedImageUrl?: string;

  constructor(isActive: boolean = true) {
    this.isActive = isActive;
  }

  static getFullName(driver: Driver) {
    const { firstName, lastName, businessPartnerName } = driver;
    const fullName = isNullOrWhitespace(lastName) ? firstName : `${firstName} ${lastName}`;
    return fullName ?? businessPartnerName ?? '';
  }

  static getInitialNameLetters(driver: any) {
    const { firstName, lastName } = driver;
    return isNullOrWhitespace(lastName)
      ? firstName
        ? firstName.substring(0, 1)
        : ''
      : firstName.substring(0, 1) + lastName.substring(0, 1);
  }
}
