import React, { useEffect, useState } from 'react';
import NumericFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Button, TextInput, SelectInput } from 'shamrock-clover-ui';
import { SimpleSnackbar } from '../core/snackbar';
import { LoadManagementService } from '../../services';
import { isNullOrWhitespace } from '../../utils/string-utils';
import css from './styles/contact-us.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

interface Props {
  plans: string[];
  selectedPlan: {};
  setRequestSubmitted: Function;
  showCancelButton?: boolean;
  onCancelButtonClicked?: Function;
}

const ContactUs: React.FC<Props> = ({
  plans,
  selectedPlan,
  setRequestSubmitted,
  showCancelButton,
  onCancelButtonClicked,
}: Props) => {
  const company = useSelector((state: any) => state.app.company);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const menuOptions: any[] = plans.map((p: string) => ({
    optionName: p,
  }));
  const [plan, setPlan] = useState(menuOptions[0]);
  const [validateData, setValidateData] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const emailValidationReg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

  useEffect(() => {
    setPlan(selectedPlan);
    setCompanyName(company.company_name);
    setPhone(company.invoicing?.phone);
    setEmail(company.invoicing?.email);
  }, [selectedPlan, company]);

  const submitRequest = async () => {
    if (
      isNullOrWhitespace(firstName) ||
      isNullOrWhitespace(lastName) ||
      isNullOrWhitespace(companyName) ||
      isNullOrWhitespace(plan.optionName) ||
      phone?.replace(/\D/g, '')?.length !== 10 ||
      !phone ||
      !emailValidationReg.test(email)
    ) {
      const snackBarConfig = {
        message: t('missing_values_try_again'),
        type: 'error',
      };
      setSnackbarConfig(snackBarConfig);
      setOpenSnackbar(true);
      setValidateData(true);
    } else {
      const data = {
        firstName: firstName,
        lastName: lastName,
        companyName: companyName,
        email: email,
        phoneNumber: phone,
        planName: plan.optionName,
      };
      try {
        setIsLoading(true);
        await new LoadManagementService().postSalesforceLead(data);
        setIsLoading(false);
        setRequestSubmitted(true);
      } catch {
        const snackBarConfig = {
          message: t('error_submitting_try_again'),
          type: 'error',
        };
        setIsLoading(false);
        setSnackbarConfig(snackBarConfig);
        setOpenSnackbar(true);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <div className={css.contactUsContainer}>
      {isLoading ? (
        <CircularProgress className={css.loadingSpinner} />
      ) : (
        <>
          <div className={css.contactUsTitle}> {t('contact_us_about_upgrading')}</div>
          <div className={css.subTitle}>{t('submitting_request_we_will_contact_you')}</div>
          <div className={css.contactInfoGroup}>
            <div className={css.contactInfoLine}>
              <TextInput
                label={`${t('First Name')} *`}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={(validateData && isNullOrWhitespace(firstName)) as any}
              />
            </div>
            <div className={css.contactInfoLine}>
              <TextInput
                label={`${t('Last Name')} *`}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={(validateData && isNullOrWhitespace(lastName)) as any}
              />
            </div>
          </div>
          <div className={css.contactInfoGroup}>
            <div className={css.contactInfoLine}>
              <TextInput
                label={`${t('Company Name')} *`}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={(validateData && isNullOrWhitespace(companyName)) as any}
              />
            </div>
            <div className={css.contactInfoLine}>
              <TextInput
                label={`${t('Email')} *`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={(validateData && !emailValidationReg.test(email)) as any}
              />
            </div>
          </div>
          <div className={css.contactInfoGroup}>
            <div className={css.contactInfoLine}>
              <NumericFormat
                label={`${t('Phone')} *`}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                format="(###) ###-####"
                mask="_"
                customInput={TextInput}
                error={(validateData && phone?.replace(/\D/g, '').length !== 10) as any}
              />
            </div>
            <div className={css.contactInfoLine}>
              <div
                className={classNames(css.plan, {
                  [css.planError]: validateData && !plan?.optionName?.length,
                })}
              >
                <SelectInput
                  label={`${t('select_a_plan')} *`}
                  onOptionSelected={(option) => setPlan(option)}
                  value={plan}
                  options={menuOptions}
                ></SelectInput>
              </div>
            </div>
          </div>
        </>
      )}
      {!isLoading && (
        <div className={classNames(css.submitRequestButton, showCancelButton ? css.actionButtons : null)}>
          {showCancelButton && (
            <Button width="114px" height="40px" buttonStyle="outlined" onClick={() => onCancelButtonClicked()}>
              {t('cancel')}
            </Button>
          )}
          <Button width="180px" height="40px" onClick={submitRequest}>
            {t('submit_request')}
          </Button>
        </div>
      )}
      <SimpleSnackbar open={openSnackbar} snackBarConfig={snackbarConfig} onClose={() => setOpenSnackbar(false)} />
    </div>
  );
};

export default ContactUs;
