import React, { ReactPropTypes } from 'react';
import { Button, Checkbox, Dialog } from '@mui/material';
const Config = require('Config');
import css from './styles/eula-dialog.scss';
import { User } from '../../interfaces';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/appActions';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import rtsproUserService from '../../services/rtspro-users.service';

interface props extends Partial<ReactPropTypes> {
  open: boolean;
  handleEulaAccepted: Function;
  user: User;
  actions: typeof appActions;
}

export function EulaDialog({ open, handleEulaAccepted, user, actions }: props) {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAcceptClicked = async () => {
    const newUser = { ...user, eulaAccepted: true };
    setLoading(true);
    try {
      user.userStatus.isRegistered
        ? await rtsproUserService.updateUser(user.email, { eulaAccepted: true })
        : await rtsproUserService.updateTrialUser(user.email, {
            eulaAccepted: true,
          });
      actions.setUser(newUser);
    } catch (error) {
      actions.showSimpleSnackBar('Error Accepting EULA.', 'error', 5000);
    } finally {
      setLoading(false);
      handleEulaAccepted();
    }
  };

  const { t } = useTranslation();
  return (
    <Dialog open={open} classes={{ paper: css.dialogPaper }}>
      {loading && <div className={css.loadingContainer} />}
      {loading && <CircularProgress className={css.loadingSpinner} variant={'indeterminate'} size={60} />}
      <h2>END USER LICENSE AGREEMENT</h2>
      <div className={css.eulaContent}>
        <h3>IMPORTANT!!!</h3>
        <hr />
        <div className={css.tsText}>
          READ THIS END USER LICENSE AGREEMENT (EULA) CAREFULLY BEFORE INSTALLING, ACCESSING, DOWNLOADING AND/OR USING
          THE LICENSED SOFTWARE PRODUCT AND RELATED DOCUMENTATION, IF ANY, (COLLECTIVELY, THE &ldquo;SOFTWARE
          PRODUCT&rdquo;). THIS AGREEMENT CONSTITUTES A LEGALLY BINDING AGREEMENT BETWEEN &ldquo;LICENSEE&rdquo; AND
          &ldquo;LICENSOR&rdquo;. IF THE SOFTWARE IS INSTALLED, ACCESSED, DOWNLOADED AND/OR USED, LICENSEE IS DEEMED TO
          HAVE UNCONDITIONALLY ACCEPTED THIS AGREEMENT AND AGREES TO BE BOUND BY ITS TERMS.&nbsp; FURTHER, BY CLICKING
          &ldquo;AGREE&rdquo; AT THE END OF THIS DOCUMENT, LICENSEE HAS DEMONSTRATED LICENSEE&rsquo;S UNCONDITIONAL
          ACCEPTANCE OF THIS AGREEMENT AND THIS AGREEMENT IS EFFECTIVE AS OF THAT DATE (&ldquo;EFFECTIVE
          DATE&rdquo;).&nbsp; IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT (AS DEMONSTRATED BY CLICKING
          &ldquo;DO NOT AGREE&rdquo;), LICENSEE IS NOT AUTHORIZED TO INSTALL, ACCESS, DOWNLOAD AND/OR USE THIS SOFTWARE
          PRODUCT; AND AS A RESULT, LICENSEE SHALL IMMEDIATELY RETURN, OR IF RECEIVED ELECTRONICALLY, DESTROY THE
          SOFTWARE PRODUCT AND THEREAFTER, CERTIFY DESTRUCTION OF THE SOFTWARE PRODUCT. FOLLOWING RETURN OF THE SOFTWARE
          PRODUCT OR RECEIPT OF CERTIFICATION OF DESTRUCTION, LICENSEE WILL RECEIVE A FULL REFUND OF ANY FEE PAID.&nbsp;
          THIS AGREEMENT, IF ACCEPTED, REPRESENTS THE COMPLETE AGREEMENT BETWEEN LICENSEE AND LICENSOR CONCERNING THE
          SOFTWARE PRODUCT, AND IT SUPERSEDES ANY PRIOR OR CONCURRENT AGREEMENT, REPRESENTATION, OR UNDERSTANDING
          BETWEEN THE PARTIES (EXCEPT LICENSOR&rsquo;S SERVICE AS A SOFTWARE (&ldquo;SaaS&rdquo;) AGREEMENT IF FULLY
          EXECUTED BY LICENSOR AND LICENSEE), INCLUDING BUT NOT LIMITED TO ANY ASSOCIATED PURCHASE ORDER TERMS AND
          CONDITIONS UNDER WHICH THE SOFTWARE PRODUCT WAS OBTAINED UNLESS OTHERWISE AGREED IN WRITING. &nbsp;
        </div>

        <div className={css.tsText}>
          Licensor and Licensee may be alternatively referred to as party, individually, and parties, collectively.
        </div>

        <div className={css.tsText}>
          It is acknowledged by Licensee and Licensor that Licensee is either: i) a sole individual (&ldquo;user&rdquo;)
          or ii) a legal entity, in which case, it is the legal entity and not the employee, agent or representative
          (&ldquo;users&rdquo;) of Licensee that is entering into this Agreement. Further, in the case of a legal
          entity, Licensor acknowledges that Licensee may have multiple authorized and recognized users networked and/or
          residing at various Licensee physical locations that may access and use the Software Product on behalf of and
          for the benefit of Licensee, and that such users will unconditionally bind Licensee to this Agreement.&nbsp;
          Notwithstanding the foregoing, Licensee assumes the entire responsibility and liability to verify
          Licensee&rsquo;s authorized and recognized users that access and utilize the Software Product. Further,
          Licensee&nbsp; may have already accepted a version of the terms and provisions in this Agreement by signing a
          separate agreement; and therefore, i) the &ldquo;individual user&rdquo; or&nbsp; ii) the &ldquo;employee,
          agent or representative&nbsp; users of &nbsp;Licensee&rdquo;&nbsp; must make inquiries to determine if such
          other agreement exists.&nbsp; If none exists, any action as recited above by an &ldquo;individual user&rdquo;
          or &ldquo;employee, agent or representative users&rdquo; binds Licensee to this Agreement.&nbsp; If an
          agreement has been signed by an authorized and recognized representative of Licensee, this Agreement shall be
          considered to contain the entire understanding of Licensee and Licensor relating to the subject matter recited
          herein and this Agreement shall supersede all prior or contemporaneous, conflicting or additional
          communications or agreements, whether oral or written, between Licensee and Licensor relating to the subject
          matter recited herein except if otherwise agreed to in writing. <strong>&nbsp;</strong>This Agreement and the
          SaaS, when fully executed by Licensee and Licensor is incorporated herein by reference, apply to all
          quotations, orders, order fulfillments and sales of the Software Product.&nbsp; Licensee reserves the right to
          cancel any order for the Software Product, at any time, prior to the shipment or delivery of the Software
          Product upon reasonable notification to Licensor.
        </div>

        <div className={css.tsText}>
          This copy of the Software Product is licensed and NOT sold to Licensee. This Software Product is protected by
          U.S. and International copyright laws and treaties, as well as other U.S. and International laws and treaties,
          both statutory and common law, related to other forms of &ldquo;Intellectual Property&rdquo;, including but
          not limited to patents, trade secrets, trademarks or any other recognized forms of common law or statutory
          proprietary protection. Shamrock Technologies, Inc. (&ldquo;Licensor&rdquo;), or its parent, subsidiaries,
          affiliates, and associated entities (collectively &ldquo;Licensor&rdquo;), owns and/or holds all right, title
          and/or interest in and to all Intellectual Property rights in the Software Product. In the event certain
          portions, modules or components of the Software Product require incorporation of third-party software (which
          third-party software will be specifically identified to Licensee by Licensor); Licensor has obtained rights to
          and holds rights in such third-party software under third-party license grants to such certain third-party
          software Intellectual Property, which grant of rights are consistent with the rights authorized hereunder with
          respect to the access, installation and/or use of the Software Product. The Software Product, and any
          installation, access and/or use thereof, is subject to all the terms and conditions of this Agreement and
          rights and interests, which may be asserted, under applicable Intellectual Property protections.
        </div>

        <h3>License Grant</h3>

        <div className={css.tsText}>
          Licensor grants Licensee a non-exclusive, non-sublicensable, non-transferable, revocable limited right to
          access, install and/or use solely by Licensee the Software Product, which may be accessed and used by multiple
          users of only Licensee at a specific Licensee location site as identified in the Saas only for the purposes of
          Licensee&rsquo;s internal business use and requirements and for no other purposes. Licensee shall be
          authorized to install and use the Software Product only with Licensor provided and/or authorized software
          and/or hardware systems and components as may be more particularly identified in Licensor user documentation
          or specifications accompanying the Software Product or quotations, orders, order fulfillments or sales
          documents associated with the Software Product. Licensee is not authorized to use, interface or facilitate
          interoperability of the Software Product with any unauthorized or non-provided and/or non-identified software
          and/or hardware system or components. Licensee is not authorized to network, timeshare, lease, distribute,
          disseminate or make available, in any manner, the Software Product to any third party. Licensee shall not be
          permitted to alter, modify, or change, in any way; copy or reproduce; create derivative works of; or
          disassemble, reverse engineer, decompile or otherwise derive the source code of the Software Product. Licensee
          may make one archival copy of the Software Product. The archival copy must be on a storage medium other than a
          hard drive, and may only be used only for back-up purposes or for the reinstallation of the Software Product
          in the event the Software Product is lost or erased.&nbsp; No other Intellectual Property rights or interests
          are granted, either express or implied, by the provision of the Software Product. No proprietary notices may
          be altered or removed on the Software Products or any work products derived therefrom.
        </div>

        <h3>Third-PartySoftware-Hardware/Updates/Maintenance/Support/CustomizedServices</h3>
        <ol>
          <li className={css.tsText}>
            If any portion, component or module of the Software Product is characterized and explicitly identified as
            &ldquo;Open Source&rdquo; to Licensee, such &ldquo;Open Source&rdquo; software code shall be subject to the
            typical GPL (General Public License) or the like that is applicable to public or open source license
            requirements for Open Source code access, use and dissemination.
          </li>
          <li className={css.tsText}>
            If any portion, component or module of the Software Product incorporates any third-party software as recited
            in Section 1 above, such third-party software shall be identified to Licensee and shall be subject to the
            terms and conditions of this Agreement, and Licensee shall not be required to separately license such
            third-party software. This Agreement shall pass through the rights to such third-party software to Licensee
            under this Agreement consistent with all the rights, warranties and remedies recited herein.
          </li>
          <li className={css.tsText}>
            Except where otherwise agreed, Licensee assumes all risks, liabilities and obligations and cancels any
            warranties recited herein when Licensee accesses, uses or combines the Software Product with any
            unauthorized third-party software or any third-party hardware or components or causes the Software Product
            to interface with any unauthorized third-party software or any third-party hardware systems or components or
            causes the Software Product to become interoperable with any unathorized third-party software or any
            third-party hardware systems or components.
          </li>
          <li className={css.tsText}>
            Unless otherwise agreed, Licensor shall not be obligated to provide error corrections, bug fixes or updates
            to the Software Product throughout the Agreement term.
          </li>
          <li className={css.tsText}>
            Unless otherwise agreed, Licensor shall not be obligated to provide training, maintenance or support for the
            Software Product nor provide any customized services.
          </li>
          <li className={css.tsText}>
            Unless otherwise agreed, Licensor shall not be obligated to provide new versions or releases during the
            Agreement term.
          </li>
        </ol>

        <h3>Confidential and/or Proprietary Information</h3>
        <ol>
          <li className={css.tsText}>
            Licensee acknowledges that Licensor owns all right, title and interest in and to the Software Product,
            format, on-line editor, any website, any links, and any associated tangible or intangible Licensor-provided
            materials, documents or other embodiments of information or data, and expressions, identified confidential
            information, including any related, associated or underlying concepts, ideas, methods, and processes;
            algorithms, source, object, binary, executable code, html and code listings; interfaces, improvements,
            enhancements, modifications, changes, alterations, versions, releases, updates, error corrections and bug
            fixes; system(s) and architecture; expressions and derivative works thereof; and Intellectual Property,
            which is in existence as of the Effective Date or which is developed, generated, or created or delivered by
            Licensor during the term of this Agreement. Licensee further acknowledges that any improvements,
            enhancements, changes, alterations, modifications or derivative works, which are developed, created or
            generated by either party, either jointly or solely, from the preceding Licensor&rsquo;s confidential and/or
            proprietary information or Intellectual Property during the term of this Agreement shall be owned and held
            by Licensor and that Licensee hereby assigns all right, title and interest therein, without further
            consideration, to Licensor to confirm Licensor&rsquo;s ownership thereof (collectively, &ldquo;Licensor
            Property&rdquo;).
          </li>
          <li className={css.tsText}>
            Licensor acknowledges that Licensee owns all right, title and interest in and to Licensee&rsquo;s data and
            information, and in and to any output or work product incorporating or embodying Licensee&rsquo;s data and
            information content that is generated when such data is ingested in and processed by the Software Product
            (collectively, &ldquo;Licensee Property&rdquo;).{' '}
            <strong>
              Licensee hereby grants Licensor and its affiliates the right to utilize the Licensee Property (i) for
              their internal research and analysis, and (ii) to market and offer new products to Licensee.
            </strong>{' '}
            Except as otherwise provided in this Agreement, Licensor shall treat Licensee Property as confidential and
            proprietary to Licensee, and refrain from disclosing or releasing to any third party Licensee Property,
            until such Licensee Property: i) comes into the public domain or becomes public knowledge through no fault
            of Licensor, or ii) becomes obsolete as is customary in the industry, or iii) is released in writing by
            Licensee, whichever of the foregoing events occurs first.&nbsp;
          </li>
          <li className={css.tsText}>
            Licensee shall consider the Software Product (including the source code and associated code listings) and
            Licensor Property, including all related technical and business information, whether disclosed in oral,
            visual or written form, to be confidential and proprietary to Licensor. Licensee shall exercise at least a
            reasonable standard of care to protect the confidentiality of the information, and to prevent the
            unauthorized use, dissemination, disclosure or publication of confidential information during the term of
            this Agreement. The term of confidentiality shall extend for five (5) years beyond the expiration and/or
            termination date of this Agreement; however, it is understood that this Agreement shall impose no
            obligations of confidentiality on Licensee where Licensor&rsquo;s confidential information or confidential
            Licensor Property: (i) was rightfully in Licensee&rsquo;s possession before receipt from Licensor; (ii) is
            or becomes a matter of public knowledge through no fault of Licensee; (iii) is rightfully received by
            Licensee from a third party without a duty of confidentiality; (iv) is disclosed by Licensor to a third
            party without a duty of confidentiality on the third party; (v) is disclosed under operation of law;
            provided that Licensee timely notifies Licensor and Licensor is given the opportunity to protest such
            disclosure; or (vi) is disclosed with Licensor&rsquo;s prior written approval.&nbsp;&nbsp;&nbsp;
          </li>
        </ol>

        <h3>Warranty/Disclaimer of Warranties</h3>
        <ol>
          <li className={css.tsText}>
            Licensor warrants and represents that the Software Product will be free of defects, bugs, or errors during
            the &ldquo;warranty period&rdquo;. The &ldquo;warranty period&rdquo; shall be for thirty (30) days from the
            date of delivery of the Software Product; and as a result, Licensee must install, access and/or use such
            Software Product promptly upon delivery. The &ldquo;warranty period&rdquo; is applicable only to errors,
            bugs, or defects that are directly traceable to the Software Product if used with authorized software and
            hardware. No warranty applies where the Software Product has been modified, altered or changed by Licensee
            or any third party or used inconsistently with Software Product specifications or used with non-recommended
            or non-authorized third-party software or hardware. Any breach of this warranty within the &ldquo;warranty
            period&rdquo; of thirty (30) days shall be subject solely to the following available remedies to be provided
            by Licensor, which election of remedy shall be at Licensor&rsquo;s election, which shall be to either: (i)
            fix the error, bug or defect or (ii) provide a replacement Software Product or (iii) issue a refund of only
            the amount of money that is equal to the exact price paid for only the Software Product line item as
            evidenced on the associated Software Product invoice (except for any shipping or delivery costs, if
            applicable). Any fix or replacement shall be subject to a new &ldquo;warranty period&rdquo; as provided
            herein above. All warranties cover only defects arising under normal use and do not include malfunctions or
            failure resulting from misuse, abuse, neglect, alteration, problems with electrical power, acts of nature,
            unusual temperatures or humidity, improper installation, or damage determined by Licensor to have been
            caused by Licensee. Any claim must be made, in writing, within the applicable &ldquo;warranty period&rdquo;.
            All limited warranties on the Software Product are granted only to Licensee and are non-transferable and
            non-assignable. The foregoing serves as Licensor&rsquo;s entire liability for the Software Product with
            respect to function, operation or performance or any other problem with the Software Product and shall serve
            as Licensee&rsquo;s sole and entire remedy with respect to the licensed Software Product.
          </li>
          <li className={css.tsText}>
            EXCEPT WHERE OTHERWISE STATED, THE SOFTWARE PRODUCT IS PROVIDED &ldquo;AS IS&rdquo;. LICENSOR MAKES NO OTHER
            WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE&nbsp; OR NON- INFRINGEMENT OR CLAIMS OF INFRINGEMENT,
            MISAPPROPRIATION, MISUSE OR UNAUTHORIZED USE FROM A THIRD PARTY OTHER THAN AS SET FORTH IN THIS AGREEMENT,
            INCLUDING THE LIMITED WARRANTY RECITED HEREIN.
          </li>
          <li className={css.tsText}>
            LICENSOR MAKES NO WARRANTY THAT THE SOFTWARE PRODUCT WILL MEET LICENSEE&rsquo;S REQUIREMENTS OR OPERATE
            UNDER LICENSEE&rsquo;S SPECIFIC CONDITIONS OF USE. LICENSOR MAKES NO WARRANTY THAT OPERATION OF THE SOFTWARE
            PRODUCT WILL BE SECURE, ERROR FREE, BUG FREE OR FREE FROM INTERRUPTION. LICENSEE MUST DETERMINE WHETHER THE
            SOFTWARE PRODUCT SUFFICIENTLY MEETS LICENSEE&rsquo;S REQUIREMENTS FOR SECURITY AND UNINTERRUPTABILITY.
            LICENSEE SHALL BEAR SOLE RESPONSIBILITY AND ALL LIABILITY FOR ANY LOSS INCURRED DUE TO FAILURE OF THE
            SOFTWARE PRODUCT TO MEET LICENSEE&rsquo;S REQUIREMENTS. LICENSOR WILL NOT, UNDER ANY CIRCUMSTANCES, BE
            RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY COMPUTER OR INFORMATION STORAGE DEVICE.
          </li>
        </ol>

        <h3>Limitation of Remedies/Limitation of Liability and Damages/Indemnity</h3>
        <ol>
          <li className={css.tsText}>
            Subject to the Section 4 warranty remedies and except as otherwise provided below, Licensor&rsquo;s entire
            liability to Licensee and Licensee&rsquo;s exclusive and sole remedy in connection with any breach of any of
            the terms and conditions, including any warranties recited herein, of this Agreement or the performance or
            use of the Software Product shall be limited up to actual and provable damages that would equal a refund of
            monies for the fees paid by Licensee under this Agreement for the six (6) months immediately preceding the
            date on which the claim arose under this Agreement in connection with a breach of the terms and conditions
            recited herein or the performance or use of the Software Product.
          </li>
          <li className={css.tsText}>
            Notwithstanding the above exclusive remedies, Licensor shall hold harmless, defend and fully indemnify
            (including a claim for reasonable attorney&rsquo;s fees and court costs) Licensee against any claims of
            misuse, misappropriation or infringement of the Intellectual Property of a third party in connection with
            the Software Product. In such event of a claim of infringement, Licensor, at its election, shall use all
            reasonable efforts to secure rights to continue the use of the Software Product without additional cost to
            Licensee; replace the Software Product with a functional equivalent; or a refund of monies for the fees paid
            by Licensee under this Agreement for the six (6) months immediately preceding the date on which the claim
            arose. Licensor&rsquo;s obligations hereunder are conditioned upon: (i) the fact that such claim does not
            arise out of or relate to any access to or use of the Software Product in combination with any hardware,
            system, software, network or other materials or service not authorized by Licensor or if Licensee or any
            third party modifies or changes in any manner the Software Product; (ii) Licensee gives Licensor prompt (not
            more than three (3) days) written notice of such claim; and (iii) Licensee provides, at Licensor&rsquo;s
            expense, commercially reasonable cooperation to facilitate the defense or settlement of such claim.
          </li>
          <li className={css.tsText}>
            Licensee agrees to indemnify, defend and hold harmless Licensor from all claims, judgments, liabilities,
            expenses, or costs (including attorney&rsquo;s fees and litigation costs) that Licensor may suffer or incur
            out of third-party claims arising from: Licensee&rsquo;s breach of this Agreement; Licensee&rsquo;s
            possession, use or operation of the Software Product; Licensee&rsquo;s unauthorized use, modification or
            misuse of the Software Product or associated Intellectual Property; claims of infringement due to
            combinations of software and hardware in connection with the Software Product as facilitated by Licensee or
            any third party; and/or any other legal or equitable claims, acts or omissions of negligence, tort,
            infringement or other causes of action associated with, arising from, based on or in connection with the
            Software Product or this Agreement; provided that Licensor is not grossly negligent or engaged in
            intentional misconduct with respect to the foregoing.
          </li>
          <li className={css.tsText}>
            UNDER NO CIRCUMSTANCES SHALL LICENSOR, ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO LICENSEE OR
            ANY OTHER PARTY FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND
            (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS) RESULTING FROM THIS AGREEMENT, OR FROM THE
            FURNISHING, PERFORMANCE, INSTALLATION, OR USE OF THE SOFTWARE PRODUCT, WHETHER DUE TO A BREACH OF CONTRACT,
            BREACH OF WARRANTY, OR THE NEGLIGENCE OF LICENSOR OR ANY OTHER PARTY, EVEN IF LICENSOR IS ADVISED BEFOREHAND
            OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT THE APPLICABLE JURISDICTION LIMITS LICENSOR&rsquo;S
            ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES, THIS DISCLAIMER SHALL BE EFFECTIVE TO THE MAXIMUM EXTENT
            PERMITTED.
          </li>
        </ol>

        <h3>Termination</h3>

        <p>This Agreement shall remain in force and effect unless one of the following occurs:</p>

        <ol>
          <li className={css.tsText}>
            Licensee may terminate, without cause, this Agreement upon thirty (30) days written notice.
          </li>
          <li className={css.tsText}>
            Licensor may terminate this Agreement in the event of a material breach by Licensee, which is not cured to
            Licensor&rsquo;s satisfaction with fifteen (15) days of receipt of a written notice of such material breach
            by Licensee.
          </li>
          <li className={css.tsText}>
            This Agreement shall immediately and automatically terminate if: Licensee becomes insolvent or ceases to do
            business; Licensee breaches any Intellectual Property, Licensor Property or Licensor confidential
            information obligations; or Licensee engages in any illegal or unlawful activity.
          </li>
          <li className={css.tsText}>
            Upon any termination hereunder: the License Grant shall cease to be exercisable; all access to and use of
            the Software Product shall be immediately terminated and removed from any hardware; any and all copies of
            the Software Product as well as archival copies of the Software Product shall be destroyed with
            certification; and all confidential information and Intellectual Property (Licensor Property, as defined,
            herein) shall be promptly returned. No refunds shall be available for any reason upon any termination
            herein; and any monies paid for the Software Product are non-refundable once paid.
          </li>
        </ol>

        <h3>Other Provisions</h3>
        <ol>
          <li className={css.tsText}>
            The parties hereto are licensor/licensee with respect to each other. Each party is not and shall not be
            deemed to be an employee, agent, contractor, partner or legal representative of the other for any purpose
            and shall not have any right, power or authority to create any obligation or responsibility on behalf of the
            other.
          </li>
          <li className={css.tsText}>
            This Agreement, and any understandings explicitly referenced herein, constitute the entire agreement between
            the parties with respect to the subject matter hereof, and supersedes any and all prior or contemporaneous
            oral or written understandings between the parties concerning the subject matter hereof. No representations,
            inducements, promises or agreements, whether oral or otherwise, between the parties not contained herein or
            incorporated herein by reference shall be of any force or effect.&nbsp; Except as set forth herein, no
            modification of or amendment to this Agreement or any waiver of any rights under this Agreement, nor any
            agreement or understanding extending this Agreement or varying its terms (including any inconsistent terms
            in any purchase order, acknowledgement or similar form) shall be effective unless in writing signed by both
            parties to this Agreement. The provisions of this Agreement are severable; and if any provision shall be
            deemed invalid or unenforceable, the applicability or validity of any other provision of this Agreement
            shall not be affected, and if any such provision shall be deemed invalid or unenforceable in any respect,
            this Agreement shall be construed as if such invalid or unenforceable provisions are not contained in this
            Agreement.&nbsp; All notices shall be in writing and addressed to the party to be served at the address
            recited above.
          </li>
          <li className={css.tsText}>
            All amendments or modifications of this Agreement shall be binding upon the parties so long as the same
            shall be in writing and executed by each of the parties hereto.
          </li>
          <li className={css.tsText}>
            Licensee shall be responsible for the payment of any and all sales, excise, or use taxes, including any
            interest and penalties thereon, imposed by any governmental authority on the installation, access, or use of
            the Software Products or on this Agreement.
          </li>
          <li className={css.tsText}>
            Licensee shall not, without Licensor&rsquo;s prior written consent transfer, assign or delegate this
            Agreement, or any rights or duties hereunder, directly, indirectly, by operation of law, or otherwise.
            Licensor&rsquo;s rights and obligations under this Agreement may be transferred, delegated or assigned and
            notice shall be provided to Licensee, as a courtesy, in the event of such occurrence. Subject to the
            foregoing, this Agreement shall be binding upon and inure to the benefit of the parties hereto and their
            successors and assigns.
          </li>
          <li className={css.tsText}>
            The following Sections shall survive termination of this Agreement: 3, 4, 5, 6 and 7.
          </li>
          <li className={css.tsText}>
            Licensee shall promptly notify Licensor of any and all actions at law or equity or claims or governmental
            administrative proceeding arising out of the operation or performance of this Agreement or the subject
            matter herein.
          </li>
          <li className={css.tsText}>
            Nonperformance of either party shall be excused to the extent that performance is rendered impossible due to
            industrial conflicts, mobilization, requisition, embargo, currency restriction, insurrection, general
            shortage of transport, material or power supply, fire, explosion, terrorism, stroke of lightning, force
            majeure and similar casualties or other events beyond a party&rsquo;s control, as well as default in
            deliveries from subcontractors due to such circumstances as defined in this clause, and such nonperformance
            continues for a period of ninety (90) days with no possibility of abating or to resume performance.
          </li>
          <li className={css.tsText}>
            The prevailing party in any legal action brought by one party against the other and arising out of this
            Agreement shall be entitled, in addition to any other rights and remedies it may have and subject to the
            limitation of liabilities herein, to reimbursement for its expenses, including court costs and reasonable
            attorneys&rsquo; fees.
          </li>
          <li className={css.tsText}>
            Export laws and regulations of the United States, and any other relevant local export laws and regulations,
            apply to and govern the handling and use of the Software Product and associated documentation (including
            technical data or information derived or resulting therefrom). Licensee agrees that Licensee shall comply
            with all such applicable export laws and regulations (including &ldquo;deemed export&rdquo; and
            &ldquo;deemed re-export&rdquo; regulations).&nbsp; Licensee agrees that no Software Product and/or
            associated materials (or the direct or indirect product thereof) will be exported, directly or indirectly,
            in violation of these laws and regulations, or will be used for any purpose prohibited by these laws
          </li>
          <li className={css.tsText}>
            Licensee acknowledge that certain U.S. and International Internet Laws may apply to the download, access and
            use of the Software Product and associated documentation; and Licensee shall assume full responsibility for
            compliance with all such applicable laws in connection with access and use of the foregoing. Licensee
            acknowledges that in the event any personal information is collected by Licensee while accessing and using
            the Software Product, Licensee shall comply with all applicable Data Privacy Laws (e.g., Data Privacy and
            Security Acts and Computer Fraud and Abuse Act) and take responsibility and liability for all appropriate
            levels of precaution in the collection of personal information and data online and shall inform any provider
            of such personal information of Licensee&rsquo;s Privacy Protection Policies. Licensor recites the terms and
            conditions of its Privacy Policy and Practices on its website, and adheres to those practices.
          </li>
          <li className={css.tsText}>
            In connection with a U.S. Government Licensee, the Software Product and any associated documentation qualify
            as &ldquo;commercial items&rdquo; as that term is defined at Federal Acquisition Regulation
            (&ldquo;FAR&rdquo;) 48 CFR 2.101, consisting of &ldquo;commercial computer software&rdquo; &ldquo;and
            &ldquo;commercial computer documentation&rdquo; as such items are used in FAR 12.212, Consistent with FAR
            12.212 and DoD Far Supp. 227.7202-1 through 227.7202-4, and notwithstanding any other FAR or other
            contractual clause to the contrary in any agreement into which this Agreement may be incorporated. U.S.
            Government Licensee will acquire the Software Product and associated documentation with only those rights
            set forth in this Agreement. Further, any U.S. Government download, access and use of the Software Product
            and associated documentation constitutes the U. S. Government&rsquo;s acknowledgement that the Software
            Product and associated documentation are &ldquo;commercial computer software&rdquo; and &ldquo;commercial
            computer software documentation&rdquo;.&nbsp; As applicable and so marked with respect to the Software
            Product and associated documentation, the U.S. Government shall acquire the Software Product and related
            documentation with at least applicable Limited Rights and Restricted Rights as defined under the relevant
            and applicable FARs and DFARs.
          </li>
          <li className={css.tsText}>
            Licensor reserves the right, upon reasonable notice and during normal business hours, to conduct an audit of
            Licensee&rsquo;s books and records related to the access and use of Software Product to verify compliance
            with this Agreement.
          </li>
          <li className={css.tsText}>
            No failure or delay by either party in exercising any right or remedy under this Agreement shall be
            construed as a waiver of such right or remedy, nor shall any single or partial exercise of any right or
            remedy preclude any further or other exercise of such right or remedy. All rights and remedies under this
            Agreement are cumulative and shall not be deemed exclusive of any other rights or remedies provided by law.
          </li>
          <li className={css.tsText}>
            This Agreement shall be governed by the laws of the State of Kansas and of the United States, as applicable,
            without regard to the applicability of the principles of conflicts of law, and any dispute between the
            parties shall be adjudicated in the competent courts in the State of Kansas. Licensor shall be entitled to
            seek all available legal and equitable remedies available. The 1980 United Nations Convention on Contracts
            for the International Sale of Goods shall not apply to this Agreement or to any sale or other transaction
            hereunder and the Uniform Computer Information Transaction Act (&ldquo;UCITA&rdquo;) shall not apply to this
            Agreement or any sale or transaction hereunder.
          </li>
        </ol>

        <h3>RTS Credit Service Online Agreement</h3>
        <h3>Online Agreement</h3>
        <div className={css.tsText}>
          Use of this website may involve viewing credit information provided by RTS Credit Service, Inc. and RTS
          Carrier Services Inc. (collectively, &quot;RTS&quot;) and the RTS interactive service is subject to the Terms
          and Conditions below. As used herein, &quot;You&quot; or &quot;Subscriber&quot; means the individual and the
          Company accessing this information.
        </div>
        <h3>Terms And Conditions</h3>
        <div className={css.tsHeader}>1. License</div>
        <div className={css.tsText}>
          You are granted a nonexclusive, nontransferable, limited license to access and use the RTS credit rating
          materials and RTS interactive service from time to time made available to you on the RTS website. This license
          includes the right to view and to obtain a printout of the credit ratings via printing commands of the RTS
          website, and to use the RTS interactive service. The RTS website, information and services licensed hereunder
          are provided for the exclusive use of the Subscriber. Only the Subscriber and its authorized employees acting
          on behalf of the Subscriber may access and use the RTS website, materials and services. Subscriber shall not
          distribute any RTS information, materials or services to any third party without RTS’ express written
          permission. Each time you access the RTS website, you represent and warrant that you intend to use the
          information you access on the website in connection with a current or anticipated business transaction or
          transactions involving the person or entity on which the information is furnished.
        </div>
        <div className={css.tsText}>
          RTS provides its credit rating materials and services using its proprietary database and information. The RTS
          Credit Service ratings are based solely on RTS Credit Service&apos;s opinion. This opinion is based on days to
          pay invoices, financial strength, business practices and other payment history information and experiences.
          RTS Credit Service disclaims all warranties as to the accuracy, completeness or adequacy of such information.
          RTS Credit Service shall have no liability for the opinions, errors, omissions or inadequacies contained in
          such information or for interpretations thereof. Opinions expressed are subject to change without notice.
        </div>
        <div className={css.tsText}>
          You may not use information included in the RTS website to determine a consumer&apos;s eligibility for (a)
          credit or insurance for personal, family or household purposes; (b) employment; or (c) a government license or
          benefit.
        </div>
        <div className={css.tsHeader}>2. RTS Interactive Website Service</div>
        <div className={css.tsText}>
          RTS requests that You as the Subscriber be honest and provide accurate information when accessing and
          utilizing the interactive service or site. THIS INTERACTIVE WEBSITE SERVICE IS MERELY A VENUE OR SYSTEM FOR
          THE SHARING OF INFORMATION. RTS DOES NOT CONTROL OR VERIFY THE ACCURACY OF THE INFORMATION PROVIDED BY OTHER
          USERS THAT IS MADE AVAILABLE THROUGH THIS SYSTEM. RTS believes that people are basically good and provide
          accurate information. RTS has no reason to believe that errors or inaccuracies exist in the information
          provided in this website.
        </div>
        <div className={css.tsText}>
          RTS requires that any information provided by you regarding another subscriber, entity or individual, is
          truthful and factual. You alone are responsible for the content of any information which you post on the
          interactive service, and for the consequences of such posting.
        </div>
        <div className={css.tsText}>
          RTS reserves the right to remove any information you provide to this site or the interactive service, if RTS
          becomes aware and determines, in its sole discretion, that you are violating any portion of this agreement
          herein.
        </div>
        <div className={css.tsText}>
          You may find other users’ interactive information to be offensive, harmful, inaccurate or deceptive. In the
          event you have a dispute with one or more interactive users of this site, and by using this site, you agree to
          accept such risks and hold RTS (and our officers, directors, agents, subsidiaries, affiliated companies, joint
          ventures and employees) harmless from any claims, demands and damages (actual and consequential) of every kind
          and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, including attorney’s
          fees, arising out of or in any way connected with your providing information for, or receiving information
          from, the interactive site or system, and you agree to hold RTS (and its officers, directors, agents,
          subsidiaries, affiliated companies, joint ventures and employees) harmless from the negligence, active or
          otherwise, of RTS (or its officers, directors, agents, subsidiaries, affiliated companies, joint ventures and
          employees) in receiving or disseminating information on this system.
        </div>
        <div className={css.tsText}>
          By either using this interactive information sharing system, by viewing the information on this site, or
          providing information for this interactive system, you agree to indemnify and hold RTS (and our officers,
          directors, agents, subsidiaries, affiliated companies, joint ventures and employees) harmless from any claim
          or demand, including any costs, liability, expense and reasonable attorneys’ fees, made by you or any third
          party due to your violation of any law or the rights of you or a third party notwithstanding any negligence on
          behalf of RTS (and its officers, directors, agents, subsidiaries, affiliated companies, joint ventures and
          employees). If you are unwilling or unable to hold RTS harmless as set forth herein DO NOT PROVIDE INFORMATION
          FOR OR TO THIS SITE.
        </div>
        <div className={css.tsHeader}>3. Disclaimers Or Warranties</div>
        <div className={css.tsText}>
          ALL INFORMATION, SERVICES AND PRODUCTS, INCLUDING THE RTS CREDIT RATINGS, PROVIDED ON THIS WEBSITE ARE
          PROVIDED &quot;AS IS&quot; WITHOUT ANY WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. RTS DOES NOT MAKE AND
          HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED. RTS DOES NOT GUARANTEE OR WARRANT THE CORRECTNESS,
          COMPLETENESS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THE RTS PRODUCTS OR
          SERVICES OR THE COMPONENTS THEREOF OR INFORMATION PROVIDED HEREUNDER. IN NO EVENT SHALL RTS (OR ITS OFFICERS,
          DIRECTORS, AGENTS, SUBSIDIARIES, AFFILIATED COMPANIES, JOINT VENTURES AND EMPLOYEES) BE LIABLE FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, HOWEVER ARISING, INCURRED BY SUBSCRIBER FROM RECEIPT OR USE OF
          INFORMATION DELIVERED HEREUNDER OR THE UNAVAILABILITY THEREOF, OR OTHERWISE IN CONNECTION WITH YOUR USE OF
          THIS SITE AND/OR SERVICE.
        </div>
        <div className={css.tsHeader}>4. Limitation Of Liability</div>
        <div className={css.tsText}>
          YOU UNDERSTAND THAT EVERY BUSINESS DECISION REPRESENTS AN ASSUMPTION OF RISK AND THAT, IN FURNISHING THE
          INFORMATION MADE AVAILABLE ON THE RTS WEBSITE, RTS DOES NOT UNDERWRITE YOUR RISK IN CONNECTION WITH ANY
          TRANSACTION WITH ANY PERSON OR ENTITY LISTED ON THE RTS WEBSITE IN ANY MANNER WHATSOEVER. NEITHER RTS, NOR ITS
          OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, AFFILIATED COMPANIES, JOINT VENTURES AND EMPLOYEES (COLLECTIVELY
          &quot;RTS&quot;) SHALL BE LIABLE TO YOU (OR ANY PERSON CLAIMING THROUGH YOU) FOR ANY LOSS OR INJURY ARISING
          OUT OF OR CAUSED IN WHOLE OR IN PART BY RTS’ ACTS OR OMISSIONS IN PROCURING, COMPILING, COLLECTING,
          INTERPRETING, REPORTING, COMMUNICATING, OR DELIVERING THE RTS CREDIT RATINGS AND OTHER INFORMATION, PRODUCTS
          AND SERVICES. IF,NOTWITHSTANDING THE FOREGOING, LIABILITY CAN BE IMPOSED ON RTS, THEN YOU AGREE THAT RTS’
          AGGREGATE LIABILITY FOR ANY AND ALL LOSSES OR INJURIES ARISING OUT OF ANY ACT OR OMISSION OF RTS IN CONNECTION
          WITH ANYTHING TO BE DONE OR FURNISHED UNDER THIS AGREEMENT, REGARDLESS OF THE CAUSE OF THE LOSS OR INJURY, AND
          REGARDLESS OF THE NATURE OF THE LEGAL OR EQUITABLE RIGHT CLAIMED TO HAVE BEEN VIOLATED, SHALL NEVER EXCEED
          $100.00 (US); AND YOU COVENANT AND PROMISE THAT YOU WILL NOT SUE RTS FOR AN AMOUNT GREATER THAN SUCH SUM.
        </div>
        <div className={css.tsText}>
          IN ADDITION, WITH REGARDS TO THE RTS INTERACTIVE WEBSITE SERVICE, RTS IS IMMUNE FROM LIABILITY UNDER §230 (C)
          OF THE FEDERAL COMMUNICATIONS DECENCY ACT OF 1996, 47 U.S.C. § 230(C), IN THE OPERATION OF ITS INTERACTIVE
          WEBSITE SERVICE.
        </div>
        <div className={css.tsHeader}>5. Excluded Uses Of The Information</div>
        <div className={css.tsText}>
          RTS is not a consumer reporting agency as defined in the Fair Credit Reporting Act. The RTS website materials,
          including the credit rating information, have not been collected in whole or in part for the purpose of
          furnishing consumer reports, as defined in the Fair Credit Reporting Act, nor is it expected that the RTS
          materials and credit rating information will be used by Subscriber to prepare a consumer report. Subscriber
          agrees not to use any part of this RTS database information for the purpose of serving as a factor in (1)
          establishing an individual&apos;s eligibility for personal credit or insurance or assessing risks associated
          with existing credit obligations, (2) evaluating an individual for initial employment, promotion, reassignment
          or retention, (3) in connection with any other personal business transaction of an individual. Further,
          Subscriber agrees not to use any of this information directly in preparation of a consumer report.
        </div>
        <div className={css.tsHeader}>6. Copyright</div>
        <div className={css.tsText}>
          The entire contents of this website are protected by copyright laws. Copyright © 2017 RTS Credit Service,
          Inc. and RTS Carrier Services, Inc. All rights reserved. Except as provided herein, no part of the materials,
          including graphics or logos, available in this Web site may be copied, photocopied, reproduced, translated or
          reduced to any electronic medium or machine-readable form, in whole or in part, without prior written consent
          of RTS Subscriber shall not remove the RTS copyright notice or any other RTS proprietary notice from RTS
          materials.
        </div>
        <div className={css.tsHeader}>7. Privacy, Registration And Personalization</div>
        <div className={css.tsText}>
          We agree to treat with care the information you entrust to us, in accordance with the disclosures we give
          during the Registration process and in our Privacy Policy. For your protection and the protection of our other
          customers and Web site users, Subscriber agrees not to share its Registration information (including Password
          and User Name) with any other person for the purpose of facilitating their access or unauthorized use of the
          RTS website information & products. Use of the RTS website pursuant to a free-trial offer shall be limited to
          one free-trial offer for Subscriber. Subscriber&apos;s use of any other user name or e-mail address to obtain
          additional free-trial offers is strictly prohibited.
        </div>
        <div className={css.tsHeader}>8. Hyperlinks</div>
        <div className={css.tsText}>
          RTS may make available links from the RTS website to other, third party sites or electronic services providers
          that are not affiliated with RTS. RTS does not control these other sites or services, and RTS makes no
          representations or endorsements whatsoever concerning those sites or services. The fact that RTS has provided
          a link to a site is not an endorsement, authorization, sponsorship, or affiliation with respect to such site,
          its owners, or its providers. You agree that under no circumstances will you hold RTS liable for any loss or
          damage caused by your use of or reliance on, any content, goods or services available on other sites.
        </div>
        <div className={css.tsHeader}>9. Miscellaneous</div>
        <div className={css.tsText}>
          This Agreement and the Terms and Conditions of Subscriber&apos;s use of the RTS website, information and
          services shall be governed by and construed in accordance with the laws of the State of Kansas. By using this
          information sharing system and providing information for this system, you hereby agree that any legal dispute
          arising hereunder shall be adjudicated pursuant to the laws of the state of Kansas and that any judicial
          action for the resolution of any legal dispute arising hereunder shall be commenced or prosecuted in the 10th
          Judicial District Court of Kansas, sitting in Johnson County, Kansas. If you initiate any such claim or
          litigation, you agree to pay all RTS’ legal fees and costs in connection with said legal matter, and further
          agree that the 10th Judicial District Court of Kansas, sitting in Johnson County, Kansas shall have exclusive
          jurisdiction and venue of all legal matters. To the extent permitted by applicable law, you agree to waive
          your right to a jury trial with respect to any judicial action brought hereunder or in connection with RTS’
          providing these services and to limit your recovery, if any, to not more than $100.00 as set forth in
          paragraph 4 above.
        </div>
        <div className={css.tsText}>
          RTS may revise these Terms and Conditions at any time, and you agree to be bound by future revisions and to
          visit the link at the bottom of the RTS member home page periodically to review the most current Terms and
          Conditions.
        </div>
        <div className={css.tsText}>
          RTS reserves the right to terminate and discontinue a Subscriber&apos;s access to and use of the RTS website,
          credit product and services without cause. If RTS elects to terminate a Subscriber&apos;s account, and the
          Subscriber is not otherwise in default under the terms of this Agreement, RTS will refund to the Subscriber
          the remaining balance, if any, of the Subscriber&apos;s subscription fee on the basis of the days remaining
          under the subscription period. Subscriber shall be entitled to no other payment from RTS.
        </div>
        <div className={css.tsText}>
          If you have any questions or disputes about the credit rating information contained in this site or you
          believe that the RTS credit rating information contained herein is inaccurate or incomplete, you may request,
          at no charge to you, that RTS research the credit rating information contained in the report at issue and RTS
          may, in its sole discretion, revise or amend that credit rating information as is appropriate. All questions
          should be addressed to the address listed in the Privacy Policy herein.
        </div>
        <div className={css.tsText}>
          RTS reserves the right to remove any information you provide to this site or the interactive service, if RTS
          becomes aware and determines, in its sole discretion, that you are violating any portion of this agreement
          herein. RTS requires that any information provided by you regarding another subscriber, entity or individual,
          is truthful and factual.
        </div>
        <div className={css.tsText}>
          The RTS website, including this Agreement and these Terms and Conditions, may be presented in both an English
          and a Spanish language version. In the event of any difference between the English and the Spanish versions,
          or in the event of any dispute between the parties, the English version shall control.
        </div>
      </div>
      <div className={css.eulaCheckbox}>
        <Checkbox
          onChange={() => setChecked(!checked)}
          checked={checked}
          color="primary"
          data-testid="pt-eula-checkbox"
        />
        <div>I have read and agree to the Terms and Conditions</div>
      </div>
      <div className={css.eulaButtons}>
        <Button
          className={css.cancelButton}
          variant={'outlined'}
          onClick={() => (window.location.href = Config.rtsproUrl)}
          data-testid="pt-eula-cancel-button"
        >
          {t('CANCEL')}
        </Button>
        <div className={css.eulaAcceptMessageAndButton}>
          <div className={css.eulaMessage}>You must accept EULA to view Loads</div>
          <Button
            disabled={!checked}
            className={!checked ? css.disabled : css.acceptButton}
            variant="outlined"
            onClick={handleAcceptClicked}
            data-testid="pt-eula-accept-button"
          >
            {t('ACCEPT')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(appActions, dispatch) };
}

export default connect(null, mapDispatchToProps)(EulaDialog);
