import { SET_BUSINESS_PARTNERS, ADD_PARTNER, UPDATE_PARTNER } from '../../constants/businessPartnerConstants';
import { BusinessPartner } from '../../interfaces/business-partner';
import { sortString } from '../../utils/sorting-utils';

const initialState = [];

export default function businessPartnerReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case SET_BUSINESS_PARTNERS:
      return sortPartners(action.partners);
    case ADD_PARTNER:
      return sortPartners([...newState, { ...action.partner }]);
    case UPDATE_PARTNER:
      const { partner } = action;
      const index = newState.map((e) => e.id).indexOf(partner.id);
      if (index > -1) {
        return sortPartners([...newState.slice(0, index), partner, ...newState.slice(index + 1)]);
      } else {
        return sortPartners(newState);
      }
    default:
      return sortPartners(newState);
  }

  function sortPartners(businessPartners: BusinessPartner[]) {
    return businessPartners.sort((a: BusinessPartner, b: BusinessPartner) => sortString(a.fullName, b.fullName));
  }
}
