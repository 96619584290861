import { DriverSchedule } from '../../interfaces/driver-schedule';
import * as DispatchConstants from '../../constants/dispatchConstants';
import { DispatchDriver } from '../../interfaces/dispatch-driver';
import { addDays, getMonday, getSunday } from '../../components/dispatch/dispatch-utils';
import { DispatchTripDetails } from '../../interfaces/dispatch-trip-details';

interface DispatchStoreState {
  startDate: Date;
  endDate: Date;
  drivers: DispatchDriver[];
  startDateBoundary: Date;
  endDateBoundary: Date;
  driverSchedules: DriverSchedule[];
  filteredDriverSchedules: DriverSchedule[];
  searchCriteria: string;
  isLoading: boolean;
  tripsInBackwardTimeFrame: boolean;
  tripsInForwardTimeFrame: boolean;
  tripDetails: DispatchTripDetails;
}

const initialState: DispatchStoreState = {
  drivers: [],
  startDate: getMonday(new Date()),
  endDate: getSunday(new Date()),
  startDateBoundary: getMonday(addDays(new Date(), -14)),
  endDateBoundary: getSunday(addDays(new Date(), 14)),
  driverSchedules: [],
  filteredDriverSchedules: [],
  searchCriteria: '',
  isLoading: false,
  tripsInBackwardTimeFrame: false,
  tripsInForwardTimeFrame: false,
  tripDetails: null,
};

export default function dispatchReducer(state = initialState, action: any) {
  switch (action.type) {
    case DispatchConstants.SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case DispatchConstants.SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case DispatchConstants.SET_DRIVER_SCHEDULES:
      return {
        ...state,
        isLoading: false,
        driverSchedules: action.schedules,
      };
    case DispatchConstants.SET_FILTERED_DRIVER_SCHEDULES:
      return {
        ...state,
        filteredDriverSchedules: action.schedules,
      };
    case DispatchConstants.SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.searchCriteria,
      };
    case DispatchConstants.SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers,
      };
    case DispatchConstants.TRIPS_EXIST_ON_NEXT_WEEK:
      return {
        ...state,
        tripsInForwardTimeFrame: action.value,
      };
    case DispatchConstants.TRIPS_EXIST_ON_PREVIOUS_WEEK:
      return {
        ...state,
        tripsInBackwardTimeFrame: action.value,
      };
    case DispatchConstants.TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK:
      return {
        ...state,
        tripsInBackwardTimeFrame: action.values.backward,
        tripsInForwardTimeFrame: action.values.forward,
      };
    case DispatchConstants.SET_DISPATCH_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case DispatchConstants.TRIPS_DETAILS:
      return {
        ...state,
        tripDetails: action.values.trip,
      };
    default:
      return state;
  }
}
