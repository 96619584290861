import { ADD_TRUCK, SET_FUEL_TYPES, SET_TRUCKS, UPDATE_TRUCK } from '../../constants/truckConstants';
import { sortString } from '../../utils/sorting-utils';
import { Truck } from '../../interfaces/truck';

const initialState = { trucks: [], fuelTypes: [] };

export default function truckReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case ADD_TRUCK:
      return {
        ...newState,
        trucks: sortTrucks([...newState.trucks, { ...action.truck }]),
      };
    case UPDATE_TRUCK:
      const { truck } = action;
      const index = newState.trucks.map((e) => e.id).indexOf(truck.id);
      if (index > -1) {
        return {
          ...newState,
          trucks: sortTrucks([...newState.trucks.slice(0, index), truck, ...newState.trucks.slice(index + 1)]),
        };
      } else {
        return { ...newState, trucks: sortTrucks(newState.trucks) };
      }
    case SET_TRUCKS:
      return { ...newState, trucks: sortTrucks(action.trucks) };
    case SET_FUEL_TYPES:
      return { ...newState, fuelTypes: action.types };
    default:
      return { ...newState, trucks: sortTrucks(newState.trucks) };
  }

  function sortTrucks(trucks: Truck[]) {
    const sortedTrucks = trucks.sort((a: Truck, b: Truck) => {
      return sortString(a.truckNumber, b.truckNumber);
    });
    return sortedTrucks;
  }
}
