import { DispatchTruck } from './dispatch-truck';

export class DispatchTrip {
  id: number;
  status: TripStatus;
  startCity: string;
  startState: string;
  endCity: string;
  endState: string;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime?: Date;
  actualEndDateTime?: Date;
  pickupCount: number;
  deliveryCount: number;
  truck: DispatchTruck;
  loadNumbers: Array<string>;
  isMissingDetails: boolean;
}

export enum TripStatus {
  Available = 'Available',
  Planned = 'Planned',
  InTransit = 'InTransit',
  Delivered = 'Delivered',
}
