export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_DRIVER_SCHEDULES = 'SET_DRIVER_SCHEDULES';
export const SET_IS_GRID_LOADING = 'SET_IS_GRID_LOADING';
export const SET_FILTERED_DRIVER_SCHEDULES = 'SET_FILTERED_DRIVER_SCHEDULES';
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_DISPATCH_LOADING = 'SET_DISPATCH_LOADING';
export const TRIPS_EXIST_ON_NEXT_WEEK = 'TRIPS_EXIST_ON_NEXT_WEEK';
export const TRIPS_EXIST_ON_PREVIOUS_WEEK = 'TRIPS_EXIST_ON_PREVIOUS_WEEK';
export const TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK = 'TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK';
export const TRIPS_DETAILS = 'TRIPS_DETAILS';
