import FirebaseAnalytics from '../../../components/core/firebase-analytics';
import { JoyrideContext } from '../context';
import { JoyrideImplementationParams } from './joyrideParams';
import * as appActions from '../../../redux/actions/appActions';

export abstract class Joyride {
  protected context: JoyrideContext;
  protected params: JoyrideImplementationParams;

  constructor(context: JoyrideContext, params: JoyrideImplementationParams) {
    this.context = context;
    this.params = params;
  }

  updateUserJoyride(metadata: any): void {
    const existingMeta = this.params.user?.metaData;
    const existingJoyrides = existingMeta?.seenJoyrides;
    const metaData = {
      ...existingMeta,
      seenJoyrides: { ...existingJoyrides, ...metadata },
    };
    this.params.userSrv.patchUser(this.params.user.email, { metaData });
    // @ts-ignore
    this.params.dispatch(appActions.setUser({ ...this.params.user, metaData }));
  }

  handleSkipJoyrideAnalyticsClick = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOAD_MANAGEMENT,
      FirebaseAnalytics.PAGES.JOYRIDE,
      {
        label: FirebaseAnalytics.LABELS.JOYRIDE_SKIP,
      },
    );
  };

  handleJoyrideStepAnalyticsClick = (step: number) => {
    const steps = {
      0: FirebaseAnalytics.LABELS.JOYRIDE_STEP1,
      1: FirebaseAnalytics.LABELS.JOYRIDE_STEP2,
      2: FirebaseAnalytics.LABELS.JOYRIDE_STEP3,
      3: FirebaseAnalytics.LABELS.JOYRIDE_STEP4,
      4: FirebaseAnalytics.LABELS.JOYRIDE_STEP5,
      5: FirebaseAnalytics.LABELS.JOYRIDE_STEP5,
    };
    const label = steps[step];
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOAD_MANAGEMENT,
      FirebaseAnalytics.PAGES.JOYRIDE,
      {
        label: label,
      },
    );
  };
}
