import * as LoadConstants from '../../constants/loadConstants';
import { Load } from '../../interfaces';
import { replaceInArray } from '../../utils/array-utils';
import { LoadListItem } from '../../interfaces/load-list-item';
import { PaginationData } from 'interfaces/pagination-data';

export interface FinishedInvoiceStatus {
  loadId: number;
  completed: string[];
  failed: string[];
}

export interface SnackBarConfig {
  open: boolean;
  message: string;
  type: 'error' | 'info' | 'success' | undefined;
  time: number;
  action: () => void;
  width: string;
}

export interface InvoiceRequestToken {
  loadId: number;
  invoiceRequestToken: number;
  processing: boolean;
}

const initialState = {
  allLoads: [],
  checkedLoads: [],
  loadListMetaData: {} as PaginationData,
  selectedLoad: undefined,
  isEdit: false,
  allChecked: false,
  unsavedChanges: false,
  highlightedLoadId: 0,
  showInvoicingJoyride: false,
  invoiceRequests: [] as InvoiceRequestToken[],
  snackbarConfig: {} as SnackBarConfig,
  finishedInvoiceStatus: [] as FinishedInvoiceStatus[],
};

export default function loadReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case LoadConstants.ADD_LOAD:
      return { ...newState, allLoads: [action.load, ...newState.allLoads] };
    case LoadConstants.SELECT_LOAD:
      return {
        ...newState,
        selectedLoad: action.selectedLoad ? action.selectedLoad : undefined,
        isEdit: !action.selectedLoad ? false : newState.isEdit,
      };
    case LoadConstants.SET_LOAD_CHANGED:
      return { ...newState, unsavedChanges: action.value };
    case LoadConstants.SET_OPEN_UNSAVED_DIALOG:
      return { ...newState, openUnsavedChangesDialog: action.value };
    case LoadConstants.SET_UNSAVED_DIALOG_FINAL_ACTION:
      return { ...newState, unsavedDialogFinalAction: action.value };
    case LoadConstants.SET_OPEN_LOAD_EDIT:
      return { ...newState, isEdit: action.value };
    case LoadConstants.LOAD_LOADS_SUCCESS: {
      const checkedIds = newState.checkedLoads.map((l) => l.id) as number[];
      const successBorderedLoads = newState.allLoads.filter((l) => l.success === true);
      const errorBorderedLoads = newState.allLoads.filter((l) => l.error === true);
      const paginationObject = action.loads;
      const loads = paginationObject.results;
      loads?.forEach((l) => {
        l.isChecked = checkedIds.includes(l.id);
        l.success = successBorderedLoads.some((s) => s.id === l.id);
        l.error = errorBorderedLoads.some((e) => e.id === l.id);
        l.currentAction = errorBorderedLoads.find((e) => e.id === l.id)?.currentAction;
        l.errorCode = errorBorderedLoads.find((e) => e.id === l.id)?.errorCode;
      });
      delete paginationObject.results;
      return {
        ...newState,
        allLoads: loads || [],
        loadListMetaData: paginationObject,
      };
    }
    case LoadConstants.UNCHECK_ALL_LOADS: {
      const allLoads = newState.allLoads.map((load) => ({
        ...load,
        isChecked: false,
      }));

      return {
        ...newState,
        checkedLoads: [],
        allChecked: false,
        allLoads: allLoads,
      };
    }
    case LoadConstants.SET_LOAD_CHECKED:
      const { newLoad } = action;
      const allLoadsIndex = newState.allLoads.map((e) => e.id).indexOf(newLoad.id);
      const checkedIndex = newState.checkedLoads.map((e) => e.id).indexOf(newLoad.id);

      if (newLoad.isChecked && checkedIndex === -1) {
        newState = {
          ...newState,
          checkedLoads: [...newState.checkedLoads, { ...newLoad }],
          isEdit: false,
          selectedLoad: undefined,
        };
      } else if (!newLoad.isChecked && checkedIndex > -1) {
        newState = {
          ...newState,
          checkedLoads: newState.checkedLoads.filter((load: LoadListItem) => load.id !== newLoad.id),
        };
      }
      if (allLoadsIndex > -1) {
        newState = {
          ...newState,
          allLoads: replaceInArray(newState.allLoads, newLoad, allLoadsIndex),
        };
      }
      return newState;
    case LoadConstants.CHECK_ALL_LOADS:
      const newCheckedLoads = newState.allLoads.map((load) => ({
        ...load,
        isChecked: true,
      }));
      const checkedLoadIds = newCheckedLoads.map((load) => load.id);
      const allLoads = newState.allLoads.map((load) => {
        if (checkedLoadIds.includes(load.id)) {
          load = { ...load, isChecked: true };
        }
        return load;
      });
      return {
        ...newState,
        allChecked: true,
        checkedLoads: newCheckedLoads,
        isEdit: false,
        selectedLoad: undefined,
        allLoads: allLoads,
      };
    case LoadConstants.DELETE_LOAD:
      return {
        ...newState,
        allLoads: newState.allLoads.filter((load: Load) => load.id !== action.load.id),
      };
    case LoadConstants.INSERT_LOAD_LIST_ITEM:
      return {
        ...newState,
        allLoads: newState.allLoads.map((load: Load) => {
          if (load.id === action.load.id) {
            return action.load;
          } else {
            return load;
          }
        }),
      };
    case LoadConstants.SET_LOAD_TRUCK:
      const loadIndex = newState.allLoads.findIndex((l) => l.id === action.payload.load.id);
      const foundLoad = {
        ...newState.allLoads[loadIndex],
        ...action.payload.load,
      };
      foundLoad.truckId = action.payload.truckId;

      return {
        ...newState,
        allLoads: replaceInArray(newState.allLoads, foundLoad, loadIndex),
      };
    case LoadConstants.SET_LOAD_DRIVER:
      const foundIndex = newState.allLoads.findIndex((l) => l.id === action.payload.load.id);
      const load = { ...newState.allLoads[foundIndex], ...action.payload.load };
      load.driverId = action.payload.driverId;
      if (!action.payload.driverId) load.driverName = null;
      else if (action.payload.driverName) load.driverName = action.payload.driverName;

      return {
        ...newState,
        allLoads: replaceInArray(newState.allLoads, load, foundIndex),
      };
    case LoadConstants.UPDATE_LOAD_SUMMARY:
      const { amount, existingAmount } = action.payload;
      let summary: {
        totalAmount: number;
        totalPayment: number;
      } = newState.loadListMetaData.summary;
      summary.totalAmount -= existingAmount ?? 0;
      summary.totalAmount += amount ?? 0;
      return {
        ...newState,
        loadListMetaData: { ...newState.loadListMetaData, summary: summary },
      };
    case LoadConstants.REMOVE_CHECKED_LOAD:
      if (
        newState.checkedLoads.length > 0 &&
        newState.checkedLoads.findIndex((load) => load.id === action.checkedLoadId) !== -1
      ) {
        const checkedLoadsIds = newState.checkedLoads.filter((load) => {
          return load.id !== action.checkedLoadId;
        });
        return { ...newState, checkedLoads: checkedLoadsIds };
      }
      return newState;
    case LoadConstants.SET_LOAD_MESSAGE_STATUS: {
      const { loadId, type, progress, error, errorCode } = action.payload;
      const loadIndex = newState.allLoads.findIndex((l) => l.id === loadId);
      if (loadIndex !== -1) {
        const updatedLoad = {
          ...newState.allLoads[loadIndex],
          progress,
          error,
          currentAction: type,
          errorCode,
        };
        return {
          ...newState,
          allLoads: [...newState.allLoads.slice(0, loadIndex), updatedLoad, ...newState.allLoads.slice(loadIndex + 1)],
        };
      }
      return newState;
    }
    case LoadConstants.CLEAR_LOAD_MESSAGE_STATUS: {
      const { loadId, highlight } = action.payload;
      const loadIndex = newState.allLoads.findIndex((l) => l.id === loadId);
      if (loadIndex !== -1) {
        const clearedLoad = {
          ...newState.allLoads[loadIndex],
          progress: undefined,
          error: undefined,
          currentAction: undefined,
          errorCode: undefined,
          success: highlight,
        };
        return {
          ...newState,
          allLoads: [...newState.allLoads.slice(0, loadIndex), clearedLoad, ...newState.allLoads.slice(loadIndex + 1)],
        };
      }
      return newState;
    }
    case LoadConstants.HIGHLIGHT_NEW_LOAD:
      return { ...newState, highlightedLoadId: action.highlightedLoadId };
    case LoadConstants.SHOW_INVOICING_JOYRIDE:
      return { ...newState, showInvoicingJoyride: action.value };
    case LoadConstants.ADD_LOAD_FOR_INVOICING: {
      const { loadId, invoiceRequestToken, processing } = action.payload;
      const invoiceInfo = { loadId, invoiceRequestToken, processing };
      const items = [...newState.invoiceRequests, invoiceInfo];
      return { ...newState, invoiceRequests: items };
    }
    case LoadConstants.CLEAR_LOAD_FOR_INVOICING: {
      const currentItems = [...newState.invoiceRequests];
      const index = currentItems.findIndex((item) => item.invoiceRequestToken === action.invoiceRequestToken);
      if (index !== -1) {
        currentItems.splice(index, 1);
      }
      return { ...newState, invoiceRequests: currentItems };
    }
    case LoadConstants.REFRESH_LOAD_FOR_INVOICING: {
      const { invoiceRequestToken, processing } = action.payload;
      const tempRequests = [...newState.invoiceRequests];
      const foundRequest = tempRequests.find((x) => x.invoiceRequestToken === invoiceRequestToken);
      foundRequest.processing = processing;
      return { ...newState, invoiceRequests: tempRequests };
    }
    case LoadConstants.SET_DOWNLOAD_FLAG: {
      const { loadId, flag } = action.payload;
      const loadIndex = newState.allLoads.findIndex((l) => l.id === loadId);
      if (loadIndex !== -1) {
        const updatedLoad = {
          ...newState.allLoads[loadIndex],
          canDownloadInvoice: flag,
        };
        return {
          ...newState,
          allLoads: [...newState.allLoads.slice(0, loadIndex), updatedLoad, ...newState.allLoads.slice(loadIndex + 1)],
        };
      }
      return newState;
    }
    case LoadConstants.ADD_FINISHED_INVOICE_STATUS: {
      const { finishedStatus } = action.payload;
      const existingItemIndex = newState.finishedInvoiceStatus.findIndex(
        (item) => item.loadId === finishedStatus.loadId,
      );
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...newState.finishedInvoiceStatus[existingItemIndex],
          completed: [...newState.finishedInvoiceStatus[existingItemIndex].completed, finishedStatus.completed],
          failed: [...newState.finishedInvoiceStatus[existingItemIndex].failed, finishedStatus.failed],
        };
        const updatedFinishedInvoiceStatus = [
          ...newState.finishedInvoiceStatus.slice(0, existingItemIndex),
          updatedItem,
          ...newState.finishedInvoiceStatus.slice(existingItemIndex + 1),
        ];
        return {
          ...newState,
          finishedInvoiceStatus: updatedFinishedInvoiceStatus,
        };
      } else {
        return {
          ...newState,
          finishedInvoiceStatus: [...newState.finishedInvoiceStatus, finishedStatus],
        };
      }
    }
    case LoadConstants.CLEAR_FINISHED_INVOICE_STATUS: {
      return { ...newState, finishedInvoiceStatus: [] };
    }
    case LoadConstants.SET_LOAD_SNACKBAR_CONFIG:
      return { ...newState, snackbarConfig: action.config };
    default:
      return newState;
  }
}
