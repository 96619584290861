import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../../../../utils/axios';
import { all, equals } from 'ramda';
const Config = require('Config');

const { reportingServiceUrl, documentServiceUrl } = Config;
const equalsRejected = equals('rejected');

export const reportingApiSlice: any = createApi({
  baseQuery: createBaseQuery(),
  reducerPath: 'reportingApi',
  // keepUnusedDataFor: 10,
  endpoints(build) {
    return {
      getLoadsSummary: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}loads/invoiced/summary`, method: 'post', data }),
      }),
      getBrokersSummary: build.query({
        query: (data) => ({
          url: `${reportingServiceUrl}loads/invoiced/partner_summary`,
          method: 'post',
          data: { ...data, limit: 10 },
        }),
      }),
      getRevenueSummary: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}loads/invoiced/revenue_summary`, method: 'post', data }),
      }),
      getFuelSummary: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}fuel/summary`, method: 'post', data }),
      }),
      getFuelByDriverType: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}fuel/by_driver_type`, method: 'post', data }),
      }),
      getTotalActiveLoads: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}trips/loads_highlight`, method: 'post', data }),
      }),
      getTotalActiveDrivers: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}drivers/active`, method: 'post', data }),
      }),
      getTotalActiveTrucks: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}trucks/active`, method: 'post', data }),
      }),
      getTotalMileage: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}trips/mileage_highlight`, method: 'post', data }),
      }),
      getBirthdays: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}drivers/birthdays`, method: 'post', data }),
      }),
      getIftaSummary: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}ifta/company_summary`, method: 'post', data }),
      }),
      getStatementsSummary: build.query({
        query: (data) => ({ url: `${reportingServiceUrl}statements/driver_type_breakdown`, method: 'post', data }),
      }),
      getPresignedUrl: build.query({
        query: (data) => ({ url: `${documentServiceUrl}file/generatepresignedurl`, method: 'post', data }),
      }),
    };
  },
});

export const selectApisFailed = (state: any) => {
  // NOTE: -1 because the birthday image queries don't count
  const count = Object.keys(reportingApiSlice.endpoints).length - 1;
  if (state.reportingApi.queries) {
    const statuses = Object.keys(state.reportingApi.queries).reduce((a: any[], q: any) => {
      if (state.reportingApi.queries[q].endpointName !== 'getPresignedUrl') {
        return [...a, state.reportingApi.queries[q].status];
      }
      return a;
    }, []);
    if (statuses.length === count && all(equalsRejected)(statuses)) {
      return true;
    }
    return false;
  }
  return false;
};

export const {
  useGetLoadsSummaryQuery,
  useGetBrokersSummaryQuery,
  useGetRevenueSummaryQuery,
  useGetFuelSummaryQuery,
  useGetFuelByDriverTypeQuery,
  useGetTotalActiveLoadsQuery,
  useGetTotalActiveDriversQuery,
  useGetTotalActiveTrucksQuery,
  useGetTotalMileageQuery,
  useGetBirthdaysQuery,
  useGetIftaSummaryQuery,
  useGetStatementsSummaryQuery,
  useGetPresignedUrlQuery,
} = reportingApiSlice;
