import { RoutesProps } from 'react-router';
import SnackBarConfig from '../../interfaces/snack-bar-config';
import { Snackbar } from '@mui/material';
import css from './styles/snackbar.scss';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface SimpleSnackbarProps extends Partial<RoutesProps> {
  open: boolean;
  snackBarConfig: SnackBarConfig;
  onClose: Function;
}

export function SimpleSnackbar({ open, onClose, snackBarConfig }: SimpleSnackbarProps) {
  const handleClose = (event) => {
    onClose();
  };
  const type = snackBarConfig.type;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      style={{ bottom: 0 }}
      autoHideDuration={snackBarConfig.time || 5000}
      className={css[snackBarConfig.type]}
      onClose={handleClose}
      message={<span id={css.messageId}>{snackBarConfig.message}</span>}
      action={[
        type === 'undo' ? (
          <Button
            key="undo"
            onClick={() => snackBarConfig.callback(handleClose)}
            size="small"
            className={css.snackbarButton}
          >
            Undo
          </Button>
        ) : (
          <IconButton className={type === 'info' ? css.white : ''} key="close" aria-label="Close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ),
      ]}
      data-testid="snackbar-message"
    />
  );
}
