import { ADD_DRIVER, SET_DRIVERS, UPDATE_DRIVER } from '../../constants/driverConstants';
import { Driver } from '../../interfaces/driver';
import { sortString } from '../../utils/sorting-utils';

const initialState = [];

export default function driverReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case ADD_DRIVER:
      return sortDrivers([...newState, { ...action.driver }]);
    case UPDATE_DRIVER:
      const { driver } = action;
      const index = newState.map((e) => e.id).indexOf(driver.id);
      if (index > -1) {
        return sortDrivers([...newState.slice(0, index), driver, ...newState.slice(index + 1)]);
      } else {
        return sortDrivers(newState);
      }
    case SET_DRIVERS:
      return sortDrivers(action.drivers);
    default:
      return sortDrivers(newState);
  }

  function sortDrivers(drivers: Driver[]) {
    const sortedDrivers = drivers.sort((a: Driver, b: Driver) => {
      const driverA = Driver.getFullName(a);
      const driverB = Driver.getFullName(b);
      return sortString(driverA, driverB);
    });
    return sortedDrivers;
  }
}
