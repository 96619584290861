import { DispatchTrip, TripStatus } from '../../interfaces/dispatch-trip';

export function tripsOverlap(trip1, trip2) {
  return (
    (trip1.actualStartDateTime < trip2.actualEndDateTime && trip1.actualEndDateTime > trip2.actualStartDateTime) ||
    (trip2.actualStartDateTime < trip1.actualEndDateTime && trip2.actualEndDateTime > trip1.actualStartDateTime)
  );
}

export function validateDatesOutsideTimeFrame(
  tripStartDate: Date,
  tripEndDate: Date,
  validationStartDate: Date,
  validationEndDate: Date,
) {
  return (
    (tripStartDate.getTime() < validationStartDate.getTime() &&
      tripEndDate.getTime() < validationStartDate.getTime()) ||
    (tripStartDate.getTime() > validationEndDate.getTime() && tripEndDate.getTime() > validationEndDate.getTime())
  );
}

export function getTripDates(trip: DispatchTrip, getInTime: boolean = false) {
  const tripStartDate = trip.status === TripStatus.Available ? trip.scheduledStartDateTime : trip.actualStartDateTime;
  const tripEndDate = trip.status === TripStatus.Available ? trip.scheduledEndDateTime : trip.actualEndDateTime;
  return getInTime
    ? {
        tripStartDate: tripStartDate.getTime(),
        tripEndDate: tripEndDate.getTime(),
      }
    : {
        tripStartDate: new Date(tripStartDate),
        tripEndDate: new Date(tripEndDate),
      };
}

export function getMonday(date: Date): Date {
  const day = date.getDay();
  const diff = (day === 0 ? -6 : 1) - day;
  const monday = new Date(date);
  monday.setDate(date.getDate() + diff);
  monday.setHours(0);
  monday.setMinutes(0);
  monday.setSeconds(0);
  monday.setMilliseconds(0);
  return monday;
}

export function getSunday(date: Date): Date {
  const day = date.getDay();
  const diff = day === 0 ? 0 : 7 - day;
  const sunday = new Date(date);
  sunday.setDate(date.getDate() + diff);
  sunday.setHours(23);
  sunday.setMinutes(59);
  sunday.setSeconds(59);
  sunday.setMilliseconds(999);
  return sunday;
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
