import React, { useState } from 'react';
import CompanyPlan from './company-plan';
import ContactUs from './contact-us';
import ThankYou from './thank-you';
import css from './styles/request-upgrade.scss';
import FirebaseAnalytics from '../../components/core/firebase-analytics';
import { useTranslation } from 'react-i18next';
import { COMPANY_PLANS } from '../../constants/company-plans';

interface Props {
  hasProTransLite: boolean;
}

const RequestUpgrade: React.FC<Props> = ({ hasProTransLite }: Props) => {
  const ref = React.useRef(null);
  const scrollRef = React.useRef(null);
  const [selectedPlan, setSelectedPlan] = useState({ optionName: '' });
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const { t } = useTranslation();

  const requestUpgradeClick = (planName: string) => {
    setSelectedPlan({ optionName: planName });
    scrollRef.current.scrollIntoView();

    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.MARKETING_PLANS,
      { label: FirebaseAnalytics.LABELS.REQUEST_UPGRADE, data: planName },
    );
  };

  return (
    <div className={css.mainContainer} ref={ref}>
      <div className={css.title}>
        <div>{t('transportation_management')}</div>
        <div>{t('software_for_fleets_of_any_size')}</div>
      </div>
      <div className={css.subTitle}>
        <div>{t('protransport_is_a_simple_and_user_friendly_transportation_management')}</div>
        <div>{t('feature_designed_to_help_you_manage_your_day_to_day_operations')}</div>
      </div>
      <div className={css.plans}>
        {COMPANY_PLANS.map((plan) => (
          <CompanyPlan
            plan={plan}
            key={plan.name}
            hasProTransLite={hasProTransLite}
            requestUpgradeClick={() => requestUpgradeClick(plan.name)}
          />
        ))}
      </div>
      <div ref={scrollRef}>
        {requestSubmitted ? (
          <ThankYou />
        ) : (
          <ContactUs
            plans={COMPANY_PLANS.map((p) => p.name)}
            selectedPlan={selectedPlan}
            setRequestSubmitted={setRequestSubmitted}
          />
        )}
      </div>
    </div>
  );
};

export default RequestUpgrade;
