import Joyride from '../../components/joyride/joyride';
import SubHeader from '../../components/headers/sub-header';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { JoyrideProvider } from '../../components/joyride/context';

interface Props {
  proTransLite: boolean;
  features: string[];
  header?: JSX.Element;
}

function Layout({ proTransLite, features, header }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    const creditIdQueryParam = location.search.includes('?id=');
    if (proTransLite && location.pathname === '/loads' && !creditIdQueryParam) {
      navigate('/loads/dashboard');
    }
  }, [proTransLite]);

  return (
    <JoyrideProvider>
      <SubHeader
        proTransLite={proTransLite}
        prefix={'/loads'}
        features={features}
        header={header}
        navigate={navigate}
      />
      <Joyride navigate={navigate} />
      <Outlet />
    </JoyrideProvider>
  );
}

export default Layout;
