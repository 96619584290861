import moment from 'moment';

export function isNullOrWhitespace(input): boolean {
  return !input || !input.trim();
}

export function formatPhoneNumber(phoneNumberString) {
  if (phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
  return null;
}

export function formatCurrency(value: number) {
  let prefix = '';
  let amount = value && !isNaN(value) ? value : 0;
  if (value < 0) {
    prefix = '-';
    amount *= -1;
  }
  return `${prefix}$${amount.toFixed(2)}`;
}

export const formatDate = (date: Date): string => {
  if (!date) return '--';

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) return '--';

  const day = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const hours = String(parsedDate.getHours()).padStart(2, '0');
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
  return `${day}/${month} ${hours}:${minutes}`;
};

export function verifyField(field: any): any {
  return field ? field : '--';
}

export function getDate(date: string, format: string = null): string {
  if (date) {
    let newDate = new Date(date.replace('Z', ''));
    return moment(newDate).format(format ? format : 'MM/DD/YYYY');
  } else {
    return date;
  }
}

export function getErrorMessage(error: any, defaultMsg: string) {
  let message: string | undefined;

  if (typeof error === 'string') message = error;

  if (error?.data && !error.data?.error && !message) message = error.data.toString();

  if (error?.response && error?.response?.data && !message) {
    const data = error.response.data;
    if (Array.isArray(data) && data.length > 0) {
      message = data[0].message;
    }
    if (typeof data === 'string') {
      message = data;
    }
  }

  if (!message && error?.message) message = error.message;

  if (!message && defaultMsg) message = defaultMsg;

  return message;
}

export function truncateString(str: string, num: number) {
  if (str.length <= num) return str;
  else return str.slice(0, num).concat('...');
}
