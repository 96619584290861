import ReactGA from 'react-ga4';
import React, { Component, ElementType, ReactNode } from 'react';
import FirebaseAnalytics from './firebase-analytics';

const Config = require('Config');

const getFirebaseModule = () => {
  return window['firebaseModuleName'] || 'TMS';
};

export default class Analytics {
  static get ACTIONS() {
    return {
      DISMISSED: 'DISMISSED',
      CLICKED: 'CLICKED',
      COMPLETED: 'COMPLETED',
      MATCHED: 'MATCHED',
      UNMATCHED: 'UNMATCHED',
      VIEWED: 'VIEWED',
      SAVED: 'SAVED',
      CREATED: 'CREATED',
      DELETED: 'DELETED',
      FOCUSED: 'FOCUSED',
      SELECT: 'SELECT',
    };
  }

  static get CATEGORIES() {
    return {
      LOAD_EDIT: getFirebaseModule() === 'Enterprise' ? 'Loads' : 'Load_Edit',
      LOAD_LIST: getFirebaseModule() === 'Enterprise' ? 'Loads' : 'Load_List',
      MY_FLEET: 'My_Fleet',
      INVOICE_SUMMARY: 'Invoice_Summary',
      INVOICE_EMAIL_MODAL: 'Invoice_Email_Modal',
      INVOICE_EMAIL_CANCEL_MODAL: 'Invoice_Email_Cancel_Modal',
      STATEMENT_EMAIL_MODAL: 'Statement_Email_Modal',
      STATEMENT_EMAIL_CANCEL_MODAL: 'Statement_Email_Cancel_Modal',
      MENU: 'Menu',
      SETTINGS: 'Settings',
      SUB_HEADER: 'Sub_Header',
      AGING: 'Aging',
      COMPANY_INFO: 'Company_Info',
      DRIVER_EDIT_MODAL: 'Driver Edit Modal',
      TRUCK_EDIT_MODAL: 'Truck_Edit_Modal',
      SUBMISSION_FAILED_SNACKBAR: 'Submission_Failed_Snackbar',
      STATEMENTS: 'Statements',
      STATEMENT_EDIT: 'Statement Edit',
      STATEMENT_VIEWER: 'Statements Viewer',
      FUEL: 'Fuel Entry',
    };
  }

  static get LABELS() {
    return {
      LOAD: 'Load',
      INVOICES: 'Invoices',
      DRIVER: 'Driver',
      TRUCK: 'Truck',
      CREATE_LOAD: 'Create Load',
      INVOICE_LOADS: 'Invoice Loads',
      SUBMIT_TO_RTS: 'Submit to RTS',
      EXPORT_INVOICES: 'Export Invoices',
      EDIT_LOAD: 'Edit Load',
      SETTINGS: 'Settings',
      COMPANY_INFO: 'Company Info',
      MY_FLEET: 'My Fleet',
      LOGOUT: 'Logout',
      AVOID_TOLLS: 'Avoid Tolls',
      HAZMAT: 'Hazmat',
      OPEN_BORDERS: 'Open Borders',
      LOAD_NUMBER: 'Load Number',
      PO_NUMBER: 'PO Number',
      AMOUNT: 'Amount',
      DEBTOR: 'Debtor',
      CONTACT_NAME: 'Contact Name',
      EMPTY_MILES: 'Empty Miles',
      LOADED_MILES: 'Loaded Miles',
      CONTACT_PHONE_NUMBER: 'Contact Phone Name',
      ADD_NOTES: 'Add Notes',
      ADD_NOTES_DRIVER: 'Add Notes Driver',
      ADD_NOTES_TRUCK: 'Add Notes Truck',
      ADD_STOP: 'Add Stop',
      ADD_DRIVER: 'Add Driver',
      ADD_INACTIVE_DRIVER: 'Add Inactive Driver',
      ADD_ACTIVE_DRIVER: 'Add Active Driver',
      ACTIVE_EXISTING_DRIVER: 'Active Existing Driver',
      INACTIVE_EXISTING_DRIVER: 'Inactive Existing Driver',
      ADD_TRUCK: 'Add Truck',
      ADD_INACTIVE_TRUCK: 'Add Inactive Truck',
      ADD_ACTIVE_TRUCK: 'Add Active Truck',
      ACTIVE_EXISTING_TRUCK: 'Active Existing Truck',
      INACTIVE_EXISTING_TRUCK: 'Inactive Existing Truck',
      REMOVE_STOP: 'Remove Stop',
      DOCUMENT: 'Document',
      DOCUMENT_DRIVER: 'Document Driver',
      DOCUMENT_TRUCK: 'Document Truck',
      AGING: 'Aging',
      EXPORT: 'Export',
      PAYMENT_DATE: 'Payment Date',
      PRINT: 'Print',
      SORT: 'Sort',
      SEARCH_NEW_DRIVER: 'Search New Driver',
      SEARCH_NEW_TRUCK: 'Search New Truck',
      UPLOAD_LOGO: 'Upload Logo',
      INVOICE_SUMMARY_VIEW_DETAILS: 'Submission View Details',
      MORE_DETAILS: 'More Details',
      START_INTEGRATION: 'Start Integration',
      STOP_INTEGRATION: 'Stop Integration',
      SAVE: 'Save',
      DELETE: 'Delete',
      CREATE_ENTRY: 'Create Entry',
    };
  }

  static initialize() {
    ReactGA.initialize(Config.googleAnalyticsAccount);
  }

  static sendEvent(category: string, action: string, label: string, value?: number) {
    const payload = {
      category: category,
      action: action,
      label: label,
    };
    if (value) {
      payload['value'] = value;
    }
    ReactGA.event(payload);
  }

  static pageView(page) {
    ReactGA.set(page);
  }

  static set(page, options) {
    ReactGA.set({
      page,
      ...options,
    });
  }

  static setDimension(key: string, value: string, additionalTrackers: string[] = []) {
    ReactGA.set({ [key]: value });
    additionalTrackers.forEach((tracker) => {
      ReactGA.set({ [key]: value, tracker });
    });
  }
}

export function tracker(WrappedComponent: any, firebasePage: string): ElementType {
  const trackPage = (page): void => {
    Analytics.set(page, {});
    Analytics.pageView(page);
    FirebaseAnalytics.setCurrentScreen(firebasePage);
    FirebaseAnalytics.logPageView(firebasePage);
  };

  const HOC = class HOC extends Component<any> {
    componentDidMount(): void {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps): void {
      const currentPage = this.props.location.pathname;
      const previousPage = prevProps.location.pathname;

      if (currentPage !== previousPage) {
        trackPage(currentPage);
      }
    }

    render(): ReactNode {
      return React.createElement(WrappedComponent, this.props);
    }
  };

  return HOC;
}
