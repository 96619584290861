import * as AppConstants from '../../constants/appConstants';

export type JoyrideKey = 'ptBasic';

const initialState = {
  company: undefined,
  user: undefined,
  tmsCompany: undefined,
  token: undefined,
  showSnackBar: false,
  showWait: false,
  stateList: undefined,
  features: undefined,
  snackBarConfig: {
    message: undefined,
    type: undefined,
    time: undefined,
    callback: undefined,
  },
  maintenanceAlert: {
    visible: false,
    title: '',
    subtitle: '',
    message: '',
    image: '',
  },
  countries: undefined,
  joyrideIntro: true,
  clientNumber: undefined,
};

export default function appReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case AppConstants.SET_COMPANY:
      return { ...state, company: { ...action.company } };
    case AppConstants.SET_TMS_COMPANY:
      return { ...state, tmsCompany: { ...action.tmsCompany } };
    case AppConstants.SET_USER:
      const newUser = action.user ? { ...action.user } : undefined;
      return { ...state, user: newUser };
    case AppConstants.SET_FINANCIAL_USER:
      return { ...state, financialUser: { ...action.financialUser } };
    case AppConstants.SET_CLIENT_NUMBER:
      return { ...state, clientNumber: action.clientNumber };
    case AppConstants.SET_TOKEN:
      return { ...state, token: { ...action.token } };
    case AppConstants.SET_SHOW_SNACK_BAR:
      return { ...newState, showSnackBar: action.enabled };
    case AppConstants.SET_SNACK_BAR_CONFIG:
      return { ...newState, snackBarConfig: action.snackBarConfig };
    case AppConstants.SET_FEATURES:
      return { ...newState, features: action.features };
    case AppConstants.SET_SHOW_WAIT:
      return { ...newState, showWait: action.enabled };
    case AppConstants.SHOW_SIMPLE_SNACK_BAR:
      return {
        ...newState,
        showSnackBar: action.enabled,
        snackBarConfig: action.snackBarConfig,
      };
    case AppConstants.SET_STATE_LIST:
      return { ...newState, stateList: action.stateList };
    case AppConstants.SET_COUNTRIES:
      return { ...newState, countries: action.countries };
    case AppConstants.SET_MAINTENANCE_ALERT:
      return { ...newState, maintenanceAlert: action.maintenanceAlert };
    case AppConstants.SET_JOYRIDE_INTRO:
      return { ...newState, joyrideIntro: action.joyrideIntro };
    case AppConstants.SET_SEEN_JOYRIDE: {
      const newMetaData = {
        ...state?.user?.metaData,
        seenJoyrides: {
          ...state?.user?.metaData?.seenJoyrides,
          [action.joyride]: action.value,
        },
      };
      return { ...newState, user: { ...state.user, metaData: newMetaData } };
    }
    default:
      return state;
  }
}
