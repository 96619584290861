import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../../../redux/configureStore';

const START_DATE = moment().subtract(13, 'day').startOf('day').toDate();
const END_DATE = moment().endOf('day').toDate();
export interface AppState {
  startDate: Date;
  endDate: Date;
  gridWidth?: number;
}

const initialState: AppState = {
  startDate: START_DATE,
  endDate: END_DATE,
  gridWidth: undefined,
};

export const appSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDates: (state, action: PayloadAction<{ startDate: Date; endDate: Date }>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setGridWidth: (state, action: PayloadAction<number | undefined>) => {
      state.gridWidth = action.payload;
    },
    clearState: () => initialState,
  },
});

const dateOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };
const formatDate = (date: Date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString('en-US', dateOptions);
  }
  return undefined;
};

export const selectStartDate = (state: RootState) => state.dashboard.startDate;
export const selectEndDate = (state: RootState) => state.dashboard.endDate;
export const selectFormattedStartDate = (state: RootState) => formatDate(state.dashboard.startDate);
export const selectFormattedEndDate = (state: RootState) => formatDate(state.dashboard.endDate);
export const selectGridWidth = (state: RootState) => state.dashboard.gridWidth;
export const { setDates, setGridWidth, clearState } = appSlice.actions;
export default appSlice.reducer;
