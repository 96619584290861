import React, { useState, useEffect } from 'react';
import MockDispatchServiceSingleton from '../../services/mock-dispatch-singleton.service';
import { FormControl, InputLabel, Select } from '@mui/material';
import { DispatchGroup } from '../../interfaces/dispatch-group';
import { DispatchDriver } from '../../interfaces/dispatch-driver';
import { Guid } from 'guid-typescript';

function DispatchTest() {
  const [driverList, setDriverList] = useState(new Array<DispatchDriver>());
  const [dispatchGroupList, setDispatchGroupList] = useState(new Array<DispatchGroup>());
  const [selectedDispatchGroup, setSelectedDispatchGroup] = useState(null);
  const [driverSchedules, setDriverSchedules] = useState(null);
  const mockDispatchService = MockDispatchServiceSingleton.getInstance(20, 20);

  useEffect(() => {
    getDispatchGroups();
  }, []);

  useEffect(() => {
    if (selectedDispatchGroup) {
      getDrivers();
    }
  }, [selectedDispatchGroup]);

  useEffect(() => {
    if (driverList) {
      getDriverSchedules();
    }
  }, [driverList]);

  const getDispatchGroups = async () => {
    const dispatchGroups = await mockDispatchService.getDispatchGroups();
    setDispatchGroupList(dispatchGroups);
  };

  const getDrivers = async () => {
    const drivers = await mockDispatchService.getDrivers(selectedDispatchGroup);
    setDriverList(drivers);
  };

  const getDriverSchedules = async () => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - 14); // 2 weeks ago
    const endDate = new Date(today);
    endDate.setDate(endDate.getDate() + 21); // 3 weeks ahead
    const driverIds = driverList.map((driver) => driver.id);
    const driverSchedules = await mockDispatchService.getDriversSchedules(
      startDate,
      endDate,
      driverIds as string[],
      selectedDispatchGroup,
    );
    setDriverSchedules(driverSchedules);
  };

  const onSelectDispatchGroup = (event: any) => {
    const value = event.target.value as number;
    setSelectedDispatchGroup(value);
  };

  return (
    <div>
      <h1>Welcome to Dispatch!</h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl variant="filled" style={{ width: '50%' }}>
          <InputLabel id="selector_label" shrink={!!selectedDispatchGroup}>
            {'Select Dispatch Group'}
          </InputLabel>
          <Select
            native
            id="dispatch_group_select"
            labelId="selector_label"
            value={selectedDispatchGroup || ''}
            onChange={onSelectDispatchGroup}
          >
            <option value={null}></option>
            {dispatchGroupList?.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Select>
        </FormControl>
        {!!driverList && driverList.length > 0 && (
          <div>
            <h2>Drivers</h2>
            <table>
              <thead>
                <tr>
                  <th>Driver ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Profile Image Key</th>
                  <th>Miles Last 30 Days</th>
                  <th>Earnings Last 30 Days</th>
                </tr>
              </thead>
              <tbody>
                {driverList?.map((driver) => (
                  <tr key={Guid.create().toString()}>
                    <td>{driver.id}</td>
                    <td>{driver.firstName}</td>
                    <td>{driver.lastName}</td>
                    <td>{driver.email}</td>
                    <td>{driver.phoneNumber}</td>
                    <td>{driver.profileImageKey}</td>
                    <td>{driver.milesLast30Days}</td>
                    <td>{driver.earningsLast30Days}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!!driverSchedules && driverSchedules.length > 0 && (
          <div>
            <h2>{'Drivers Schedules'}</h2>
            <table>
              <thead>
                <tr>
                  <th>Driver Name</th>
                  <th>Time Off</th>
                  <th>Trips</th>
                </tr>
              </thead>
              <tbody>
                {driverSchedules?.map((driverSchedule) => (
                  <tr key={Guid.create().toString()}>
                    <td>{`${driverSchedule.driver.firstName} ${driverSchedule.driver.lastName}`}</td>
                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {driverSchedule?.timeOffs?.map((timeOff) => (
                            <tr key={Guid.create().toString()}>
                              <td>{`${timeOff.startDate.toDateString()} ${timeOff.startDate.toTimeString()}`}</td>
                              <td>{`${timeOff.endDate.toDateString()} ${timeOff.endDate.toTimeString()}`}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {driverSchedule?.trips?.map((trip) => (
                            <tr key={Guid.create().toString()}>
                              <td>{`${trip.scheduledStartDateTime.toDateString()} ${trip.scheduledStartDateTime.toTimeString()}`}</td>
                              <td>{`${trip.scheduledEndDateTime.toDateString()} ${trip.scheduledEndDateTime.toTimeString()}`}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
export default DispatchTest;
