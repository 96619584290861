import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles/invalid-wex-credentials-dialog.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const InvalidWexCredentialsDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: css.dialogContainer }}>
      <DialogTitle>
        <div className={css.title}>{t('invalid_wex_credentials')}</div>
      </DialogTitle>
      <DialogContent>
        <div className={css.message}>
          <span>
            {t('invalid_wex_credentials_description')}{' '}
            <span className={css.wexLink}>efswex-customersoftwaresupport@wexinc.com</span>.
          </span>
        </div>
      </DialogContent>
      <DialogActions className={css.actionItems}>
        <Button className={css.cancelButton} variant="contained" onClick={() => onClose()}>
          {t('got_it')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InvalidWexCredentialsDialog;
